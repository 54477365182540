// AppRouter.js
import React from 'react';
import { HashRouter,BrowserRouter as Router, Route, Routes, Switch, Navigate } from 'react-router-dom';
  
import Home from '../Page/Home';

const AppRouter = () => { 
  return (
    <Router>  
    <Routes>  
        <Route  path="/" element={<Home/>}  /> 
    </Routes>
    </Router>
  );
};

export default AppRouter;