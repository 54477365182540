import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS 
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import unregisterServiceWorker from './serviceWorkerDev'; // Import the service worker unregister function

import App from './components/App/App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

serviceWorkerRegistration.register();


// Unregister the service worker in development
if (process.env.NODE_ENV === 'development') {
  unregisterServiceWorker();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
