
import React, { useEffect, useContext, useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; 

import { useTranslation } from 'react-i18next';
import i18n from 'i18next'; // Import i18next
import { initReactI18next } from 'react-i18next';

import enTranslations from '../i18n/en.json';
import zhTranslations from '../i18n/zh.json';  
import AppRouter from './AppRouter';

const lang = localStorage.getItem('lang');

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      zh: { translation: zhTranslations }, 
    },
    lng: lang ? lang : 'en', 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
});
 
function App() { 
    // Initialize the language based on localStorage on app load
    useEffect(() => {
      const storedLang = localStorage.getItem('lang');  
      if (storedLang) {
        i18n.changeLanguage(storedLang); 
      }  
    }, [i18n]);
 
    return( 
        <div className="wrapper" > 
          <AppRouter />
        </div>   
    );
}

export default App;